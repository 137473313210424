<template>
  <div class="invitation_edit">
    <el-card>
      <div class="invitation_add_title">
        <span></span>
        <p>邀请码信息</p>
      </div>
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="120px"
        class="form"
      >
        <el-form-item label="书籍名称" prop="bookName">
          <el-select
            disabled
            filterable
            @change="bookXun"
            value-key="id"
            v-model="ruleForm.bookName"
            placeholder="请选择书籍名称"
          >
            <el-option
              v-for="(item, index) in bookList"
              :key="index"
              :label="item.bookName"
              :value="item"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="邀请码数量" prop="totalNumberCode">
          <el-input
            class="input"
            type="number"
            v-model="ruleForm.totalNumberCode"
            placeholder="请输入邀请码数量"
          />
        </el-form-item>
        <el-form-item label="渠道分配" prop="channelQueryList">
          <div class="form_list">
            <div
              class="form_list_li"
              v-for="(tt, ii) in ruleForm.channelQueryList"
              :key="ii"
            >
              <el-form-item
                :prop="`channelQueryList[${ii}].channelName`"
                :rules="{
                  required: true,
                  message: '请选择渠道商',
                  trigger: 'change',
                }"
              >
                <el-select
                  disabled
                  v-model="tt.channelName"
                  placeholder="请选择渠道商"
                  value-key="id"
                >
                  <el-option
                    v-for="(item, index) in allocationList"
                    :key="index"
                    :value="item.channelName"
                    :label="item.channelName"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <div
                  v-for="(num, numIndex) in tt.channelQueryList"
                  :key="numIndex"
                  class="margintop_top"
                >
                  <el-input
                    :disabled="!num.defaults"
                    type="number"
                    style="margin: 0 20px"
                    v-model="tt.channelQueryList[numIndex].number"
                    placeholder="请输入数量"
                  />
                  <el-button
                    icon="el-icon-plus"
                    circle
                    type="success"
                    plain
                    @click="addDomain(ii)"
                  ></el-button>
                  <el-button
                    size="small"
                    v-if="num.editDefaults"
                    @click="editUpdata(num, ii)"
                    >确定</el-button
                  >
                  <!-- <el-button
                    icon="el-icon-minus"
                    circle
                    type="danger"
                    plain
                    v-if="numIndex > 0"
                    @click.prevent="removeDomain(num, ii)"
                  ></el-button> -->
                  <el-button
                    v-if="!num.defaults"
                    @click="download(ii, numIndex)"
                    style="margin-left: 30px"
                    type="primary"
                    >导出</el-button
                  >
                  <el-button
                    v-if="num.exportShow"
                    @click="download(ii, numIndex)"
                    style="margin-left: 30px"
                    type="primary"
                    >导出</el-button
                  >
                </div>
              </el-form-item>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="时效日期">
          <el-date-picker
            v-model="time"
            disabled
            @change="timeXun"
            value-format="yyyy-MM-dd"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item class="fixed_bottom">
          <!-- <el-button type="primary" @click="editAllocation">保存</el-button> -->
          <el-button plain class="new_btn" @click="cancelClick">取消</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import {
  detailsIdInvitation,
  bookAll,
  channerAll,
  invitionEdit,
  googleAPiNumber,
} from "@/api/invitation.js";
import axios from "axios";
export default {
  name: "invitationEdit",
  data() {
    return {
      exportShow: true,
      time: "",
      ruleForm: {
        bookId: "", //书籍编号
        bookName: "", //书籍名称
        id: "",
        channelQueryList: [
          //渠道商基本信息
          {
            channelId: "", //渠道商编号
            channelName: "", //渠道商名称
            googleId: "", //基本信息编号
            number: "", //数量
            value: {},
            channelQueryList: [
              {
                number: "",
              },
            ],
          },
        ],
        endTime: "", //二维码结束时间
        startTime: "", //二维码开始时间
        totalNumberCode: "", //码数总量
      },
      bookList: [],
      allocationList: [],
      rules: {
        bookName: [
          { required: true, message: "请选择书籍名称", trigger: "change" },
        ],
        totalNumberCode: [
          { required: true, message: "请输入邀请码数量", trigger: "blur" },
        ],
        channelQueryList: [{ required: true, message: "请输入渠道分配" }],
      },
    };
  },
  computed: {
    id() {
      return this.$route.query.id;
    },
    Authorization() {
      return sessionStorage.getItem("token");
    },
  },
  created() {
    this.getByIdInvitaiton(); //获取单个邀请码信息
    this.getBookAll();
    this.getAllocationAll();
  },
  methods: {
    //修改码表
    async editUpdata(row, index) {
      const query = {
        googleId: this.ruleForm.id,
        queries: {
          chId: this.ruleForm.channelQueryList[index].id,
          number: row.number,
        },
        totalNumber: this.ruleForm.totalNumberCode,
      };
      const { data: res } = await googleAPiNumber(query);
      if (res.code === 10200) {
        row.exportShow = true;
        row.editDefaults = false;
        this.getByIdInvitaiton();
      } else if (res.code === 10510) {
        this.$message.error(res.message);
      } else {
        this.$message.error(res.message);
      }
    },
    //下载二维码
    async download(ii, index) {
      const query = {
        channelQueryList: {
          channelId: this.ruleForm.channelQueryList[ii].channelId,
          googleId: this.ruleForm.channelQueryList[ii].googleId,
          id: this.ruleForm.channelQueryList[ii].channelQueryList[index].id,
          number:
            this.ruleForm.channelQueryList[ii].channelQueryList[index].number *
            1,
        },
        scene: this.ruleForm.bookId + "",
      };
      // const { data: res } = await downloadQRcode(query);
      // console.log(res);
      axios({
        method: "post",
        url: `${process.env.VUE_APP_BASE_API}/bookQrCode/download`,
        data: query,
        responseType: "blob",
        headers: {
          // 设置请求头
          Authorization: this.Authorization,
        },
      })
        .then((res) => {
          let blob = new Blob([res.data]);
          let href = window.URL.createObjectURL(blob); //创建新的URL表示指定的blob对象
          const a = document.createElement("a"); //创建a标签
          a.style.display = "none";
          a.href = href; // 指定下载'链接
          a.download = "vecto.zip"; //指定下载文件名
          // a.download = "test.png";
          a.click(); //触发下载
          window.URL.revokeObjectURL(a.href); //释放URL对象
          if (document.body.contains(a)) {
            document.body.removeChild(a); //释放标签
          }
        })
        .catch((err) => {
          this.$message.error("导出失败!");
        });
    },
    //删除单个票类
    removeDomain(item, number) {
      var index =
        this.ruleForm.channelQueryList[number].channelQueryList.indexOf(item);
      if (index !== -1) {
        this.ruleForm.channelQueryList[number].channelQueryList.splice(
          index,
          1
        );
      }
    },
    //新增单个票类
    addDomain(index) {
      this.ruleForm.channelQueryList[index].channelQueryList.push({
        number: "",
        defaults: true,
        exportShow: false,
        editDefaults: true,
      });
    },
    //取消
    cancelClick() {
      this.$router.go(-1);
    },
    editAllocation() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          const { data: res } = await invitionEdit(this.ruleForm);
          if (res.code === 10200) {
            this.$message.success("编辑成功");
            this.exportShow = true;
          } else if (res.code === 10510) {
            this.$message.error(res.message);
          }
        }
      });
    },
    //书籍名称选择
    bookXun(value) {
      this.ruleForm.bookId = value.id;
      this.ruleForm.bookName = value.bookName;
    },
    //时效时间选择
    timeXun(val) {
      (this.ruleForm.startTime = val[0]), (this.ruleForm.endTime = val[1]);
    },
    //获取所有渠道
    async getAllocationAll() {
      const { data: res } = await channerAll();
      if (res.code === 10200) {
        this.allocationList = res.data;
      }
    },
    //获取书籍信息
    async getBookAll() {
      const { data: res } = await bookAll();
      if (res.code === 10200) {
        this.bookList = res.data;
      }
    },
    //获取单个邀请码信息
    async getByIdInvitaiton() {
      const { data: res } = await detailsIdInvitation({ id: this.id });
      if (res.code === 10200) {
        this.ruleForm = res.data;
        this.time = [res.data.startTime, res.data.endTime];
      }
    },
  },
};
</script>

<style lang="less" scoped>
.invitation_edit {
  width: 100%;
  overflow: auto;
  .invitation_add_title {
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;

    span {
      width: 4px;
      height: 18px;
      background-color: #4aa9ff;
      border-radius: 2px;
      display: block;
      margin-right: 8px;
    }

    p {
      color: #151515;
      font-size: 16px;
      font-weight: 500;
    }
  }
  .form {
    margin-top: 40px;
    /deep/ .el-select {
      width: 450px;
    }

    /deep/ .input {
      width: 240px !important;
    }

    // 全局css 加上以下代码，可以隐藏上下箭头
    // 取消input的上下箭头
    /deep/input::-webkit-inner-spin-button {
      -webkit-appearance: none !important;
    }

    /deep/input::-webkit-outer-spin-button {
      -webkit-appearance: none !important;
    }

    /deep/input[type="number"] {
      -moz-appearance: textfield;
    }
  }

  .item {
    /deep/ .el-button--primary {
      background-color: #8483d0;
      border-color: #8483d0;
    }
  }

  .form_list {
    width: 100%;
    overflow: hidden;

    .form_list_li {
      width: 100%;
      margin-top: 16px;
      overflow: hidden;
      box-sizing: border-box;
      padding: 15px 8px;
      border-radius: 4px;
      border: 1px dashed #eee;
      display: flex;
      flex-direction: row;
      align-items: flex-start;

      /deep/ .el-input {
        width: 140px !important;
      }

      /deep/ .el-select {
        width: 240px !important;
      }

      /deep/ .el-input--suffix {
        width: 240px !important;
      }

      /deep/ .el-button--primary {
        background-color: #8483d0;
        border-color: #8483d0;
      }
    }
  }

  .margintop_top {
    margin-top: 10px;
  }

  .el-form-item__content .margintop_top:nth-child(1) {
    margin-top: 0;
  }

  .fixed_bottom {
    width: 100%;
    overflow: hidden;
    margin-top: 60px;

    .el-button--primary {
      width: 136px;
      height: 40px;
      background-color: #8483d0;
      border: 1px solid #8483d0;
    }

    .new_btn {
      color: #8483d0;
    }

    .el-button {
      width: 136px;
      height: 40px;
      border: 1px solid #8483d0;
    }
  }
}
</style>